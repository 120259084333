import styled from 'styled-components';

import { Inner } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';
import {
  CarouselNextArrow,
  CarouselPaginationDots,
  CarouselPrevArrow,
} from '../../atoms/Carousel/styles';
import media from '../../../common/MediaQueries';

export const ImagesSlider = styled(Inner)`
  ${CarouselPaginationDots} {
    margin-top: 2.5rem;
  }
  @media ${media.phone} {
    .slick-list {
      width: calc(100% + 3rem);
      margin-left: -1.5rem;
    }
    .slick-slide {
      padding: 0 1.5rem;
    }
    ${CarouselPrevArrow},
    ${CarouselNextArrow} {
      position: absolute;
      bottom: 0;
    }
    ${CarouselNextArrow} {
      right: 0;
    }
    ${CarouselPrevArrow} {
      right: 2.5rem;
    }
  }
`;

export const ImagesSliderItem = styled.div`
  @media ${media.phone} {
    max-width: 45.125rem;
    margin: 0 auto;
  }
`;

export const ImagesSliderMedia = styled.div`
  height: 26.75rem;
`;

export const ImagesSliderTitle = styled(Typography)`
  margin-top: 1.5rem;
`;

export const ImagesSliderSubtitle = styled(Typography)`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;
