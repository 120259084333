import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import * as Styled from './styles';
import { settings, imagesSliderItemTemplate } from './consts';
import { ImagesSliderProps } from './types';

const ImagesSlider: React.FC<ImagesSliderProps> = ({ data: { items } }) => (
  <Styled.ImagesSlider>
    {items?.length === 1 && imagesSliderItemTemplate(items[0])}
    {items?.length > 1 && (
      <Slider {...settings}>{items.map((item) => imagesSliderItemTemplate(item))}</Slider>
    )}
  </Styled.ImagesSlider>
);

export default ImagesSlider;
