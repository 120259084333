import React from 'react';
import { Settings as CarouselSettings } from 'react-slick';

import * as Styled from './styles';
import { ImagesSliderItemProps } from './types';
import * as CarouselStyled from '../../atoms/Carousel/styles';
import { IconArrowRightRound } from '../../../assets/svg';
import Image from '../../atoms/Image';

export const settings: CarouselSettings = {
  dots: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  infinite: false,
  speed: 1000,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        slidesToShow: 1,
        fade: true,
      },
    },
  ],
  nextArrow: (
    <CarouselStyled.CarouselNextArrow aria-label="Next Slide">
      <IconArrowRightRound />
    </CarouselStyled.CarouselNextArrow>
  ),
  prevArrow: (
    <CarouselStyled.CarouselPrevArrow aria-label="Prev Slide">
      <IconArrowRightRound />
    </CarouselStyled.CarouselPrevArrow>
  ),
  appendDots: (dots: React.ReactNode) => (
    <CarouselStyled.CarouselPaginationDots>{dots}</CarouselStyled.CarouselPaginationDots>
  ),
  customPaging: () => <CarouselStyled.CarouselPagination />,
};

export const imagesSliderItemTemplate = (item: ImagesSliderItemProps) => (
  <Styled.ImagesSliderItem key={item.key}>
    <Styled.ImagesSliderMedia>
      <Image {...item.image} />
    </Styled.ImagesSliderMedia>
    <Styled.ImagesSliderTitle variant="textL">{item.title}</Styled.ImagesSliderTitle>
    {item.subtitle && (
      <Styled.ImagesSliderSubtitle variant="textM">{item.subtitle}</Styled.ImagesSliderSubtitle>
    )}
  </Styled.ImagesSliderItem>
);
