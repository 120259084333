import { graphql } from 'gatsby';
import React from 'react';

import { getImage } from '../../../../utils/sanityTypes';
import ImagesSlider from '../../ImagesSlider';
import { ImagesGallerySectionProps } from './types';

const ImagesGallerySection: React.VFC<ImagesGallerySectionProps> = ({ items }) => (
  <ImagesSlider
    data={{
      items: (items ?? []).map((item) => {
        const { _key: key, title, subtitle, image } = item ?? {};

        return {
          key: key ?? '',
          title: title ?? '',
          subtitle: subtitle ?? '',
          image: getImage(image),
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment ImagesGallerySectionFragment on SanityImagesGallerySection {
    _key
    _type
    items {
      _key
      title
      subtitle
      image {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default ImagesGallerySection;
